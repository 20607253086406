import ProgressiveImage from 'react-progressive-image';

import { Logo } from './Logo';

import Large from './assets/banner/1500x612.jpg';
import Small from './assets/banner/600x245.jpg';

// export type BannerProps = {
// 	root?: Exclude<React.HTMLAttributes<HTMLDivElement>, 'style'> & {
// 		style?: Exclude<
// 			React.HTMLAttributes<HTMLDivElement>['style'],
// 			'maxHeight'
// 		>;
// 	};
// 	img?: Exclude<React.ImgHTMLAttributes<HTMLImageElement>, 'style'> & {
// 		placeholder?: string;
// 		style?: Exclude<
// 			React.ImgHTMLAttributes<HTMLImageElement>['style'],
// 			'maxHeight' | 'width' | 'zIndex'
// 		>;
// 	};
// };

// export const Banner: React.FC<BannerProps> = ({
// root: { className: rootClassName, style: rootStyle, ...rootProps } = {}
// }) => (
export const Banner: React.FC = () => (
	<div className="pt-5 position-relative">
		<ProgressiveImage src={Large} placeholder={Small}>
			{src => (
				<img
					className="mx-auto d-block"
					src={src}
					alt="Several of our cutting boards on display."
					style={{
						maxHeight: '40vh',
						maxWidth: '100%'
					}}
				/>
			)}
		</ProgressiveImage>

		<Logo
			className="position-absolute start-50 translate-middle-x"
			style={{
				top: '10%',
				width: '75%',
				maxHeight: '40vh'
			}}
		/>
	</div>
);
