import { Banner } from './Banner';
// import { Logo } from './Logo';

import './App.scss';

const App: React.FC = () => (
	<div className="container pb-5">
		<div className="pb-5">
			<Banner />
			{/* <Logo className="position-absolute top-0 start-0 end-0 bottom-0" /> */}
		</div>

		{/* <h1 className="display-1 pb-3 mb-3 border-bottom border-primary">
			WW Woodworking
		</h1> */}

		<p>WW Woodworking offers custom woodworking services.</p>

		<p>
			Located in Muskegon, Michigan, you can find us at the Muskegon
			Farmer's Market on Saturdays.
		</p>

		<a href="https://www.etsy.com/shop/WWWoodworkMuskegon">Shop</a>
	</div>
);

export default App;
