export type LogoProps = Exclude<React.SVGProps<SVGSVGElement>, 'viewbox'>;

export const Logo: React.FC<LogoProps> = props => (
	<svg viewBox="0 0 1921 678.6471" preserveAspectRatio="xMidYMin" {...props}>
		<defs>
			<style
				dangerouslySetInnerHTML={{
					__html: '.b{fill:#fff;}.c{fill:#a7a9ac;}.d{fill:rgba(0,0,0,0.5);stroke:#fff;stroke-miterlimit:10;stroke-width: 2px; z-index: -1;}'
				}}
			/>
		</defs>
		<rect className="d" x=".5" y=".5" width={1920} height="677.6471" />
		<g>
			<path
				className="b"
				d="M530.8242,142.8081c-2.6787-5.3521-5.5586-9.7695-8.6328-13.252-3.0811-3.4756-5.9609-6.0234-8.6348-7.6294v-3.2129h72.2832v3.2129c-3.752,1.876-6.293,4.9565-7.6299,9.2358-1.3428,4.2856-.6709,9.374,2.0078,15.2598l80.3135,168.2573,60.2354-114.4473-25.7002-56.2197c-2.6797-6.1553-5.4902-11.043-8.4336-14.6572-2.9492-3.6138-5.6211-6.0859-8.0312-7.4287v-3.2129h69.873v3.2129c-3.4824,1.876-5.8916,4.8188-7.2285,8.8345-1.3418,4.0156-.5391,9.5059,2.4102,16.4644l75.0938,165.0439,76.6992-161.8315c3.7461-7.7617,4.9502-13.854,3.6133-18.2715-1.3418-4.417-3.8828-7.8306-7.6289-10.2397v-3.2129h69.873v3.2129c-2.6797,1.3428-5.4902,3.6831-8.4336,7.0273-2.9492,3.3506-5.8916,8.2319-8.834,14.6572l-122.8809,257.0039h-4.0156l-74.6914-156.2104-84.3291,156.2104h-4.417l-122.8809-257.8071Z"
			/>
			<path
				className="b"
				d="M976.9648,142.8081c-2.6797-5.3521-5.5596-9.7695-8.6348-13.252-3.0801-3.4756-5.9609-6.0234-8.6328-7.6294v-3.2129h72.2822v3.2129c-3.752,1.876-6.2939,4.9565-7.6299,9.2358-1.3428,4.2856-.6719,9.374,2.0078,15.2598l80.3135,168.2573,60.2354-114.4473-25.7002-56.2197c-2.6797-6.1553-5.4902-11.043-8.4336-14.6572-2.9482-3.6138-5.6211-6.0859-8.0312-7.4287v-3.2129h69.873v3.2129c-3.4824,1.876-5.8916,4.8188-7.2275,8.8345-1.3428,4.0156-.54,9.5059,2.4092,16.4644l75.0928,165.0439,76.7002-161.8315c3.7461-7.7617,4.9502-13.854,3.6143-18.2715-1.3428-4.417-3.8848-7.8306-7.6299-10.2397v-3.2129h69.873v3.2129c-2.6797,1.3428-5.4902,3.6831-8.4336,7.0273-2.9492,3.3506-5.8916,8.2319-8.834,14.6572l-122.8799,257.0039h-4.0156l-74.6924-156.2104-84.3291,156.2104h-4.417l-122.8799-257.8071Z"
			/>
		</g>
		<g>
			<path
				className="c"
				d="M490.8008,460.9619c-1.8516-3.916-3.752-6.3623-5.7109-7.3418v-.9785h15.498v.9785c-3.4814,1.1982-4.3506,3.6992-2.6094,7.5049l41.7637,92.6621,31.6484-65.4189-11.9102-27.5693c-1.7402-3.8057-3.6445-6.1992-5.709-7.1787v-.9785h15.334v.9785c-1.7402,.7627-2.8008,1.7695-3.1807,3.0186-.3818,1.251-.1377,2.9639,.7344,5.1387l40.2949,92.0098,41.1104-90.3789c2.2842-5.4365,1.5215-8.6992-2.2832-9.7881v-.9785h15.334v.9785c-1.957,.8721-4.0244,3.7529-6.1992,8.6465l-47.1465,103.7559h-1.6309l-31.4863-70.3125-34.4219,70.3125h-1.6309l-47.7988-105.0605Z"
			/>
			<path
				className="c"
				d="M663.1562,548.4033c-3.3184-5.873-4.9756-12.5615-4.9756-20.0664,0-7.6113,1.6572-14.3555,4.9756-20.2285,3.3164-5.873,7.9121-10.4668,13.7852-13.7852,5.873-3.3164,12.5615-4.9756,20.0664-4.9756,7.6113,0,14.3281,1.6592,20.1465,4.9756,5.8174,3.3184,10.3848,7.9121,13.7041,13.7852,3.3164,5.873,4.9756,12.6172,4.9756,20.2285,0,7.5049-1.6592,14.1934-4.9756,20.0664-3.3193,5.873-7.8867,10.4971-13.7041,13.8662-5.8184,3.3701-12.5352,5.0576-20.1465,5.0576-7.5049,0-14.1934-1.6875-20.0664-5.0576-5.873-3.3691-10.4688-7.9932-13.7852-13.8662Zm62.2373-2.6924c2.8271-4.9473,4.2412-10.7383,4.2412-17.374,0-6.7422-1.4141-12.5869-4.2412-17.5371-2.8291-4.9473-6.6885-8.8096-11.583-11.583-4.8945-2.7725-10.4961-4.1592-16.8027-4.1592s-11.9092,1.3867-16.8037,4.1592c-4.8936,2.7734-8.7275,6.6357-11.501,11.583-2.7734,4.9502-4.1602,10.7949-4.1602,17.5371,0,6.6357,1.3867,12.4268,4.1602,17.374,2.7734,4.9502,6.6074,8.8096,11.501,11.583,4.8945,2.7734,10.4951,4.1602,16.8037,4.1602s11.9082-1.3867,16.8027-4.1602,8.7539-6.6328,11.583-11.583Z"
			/>
			<path
				className="c"
				d="M754.1875,548.4033c-3.3193-5.873-4.9766-12.5615-4.9766-20.0664,0-7.6113,1.6572-14.3555,4.9766-20.2285,3.3154-5.873,7.9121-10.4668,13.7842-13.7852,5.873-3.3164,12.5615-4.9756,20.0654-4.9756,7.6113,0,14.3281,1.6592,20.1484,4.9756,5.8164,3.3184,10.3848,7.9121,13.7031,13.7852,3.3164,5.873,4.9756,12.6172,4.9756,20.2285,0,7.5049-1.6592,14.1934-4.9756,20.0664-3.3184,5.873-7.8867,10.4971-13.7031,13.8662-5.8203,3.3701-12.5371,5.0576-20.1484,5.0576-7.5039,0-14.1924-1.6875-20.0654-5.0576-5.8721-3.3691-10.4688-7.9932-13.7842-13.8662Zm62.2363-2.6924c2.8262-4.9473,4.2412-10.7383,4.2412-17.374,0-6.7422-1.415-12.5869-4.2412-17.5371-2.8301-4.9473-6.6895-8.8096-11.583-11.583-4.8936-2.7725-10.4971-4.1592-16.8037-4.1592s-11.9082,1.3867-16.8027,4.1592c-4.8945,2.7734-8.7275,6.6357-11.501,11.583-2.7734,4.9502-4.1602,10.7949-4.1602,17.5371,0,6.6357,1.3867,12.4268,4.1602,17.374,2.7734,4.9502,6.6064,8.8096,11.501,11.583s10.4941,4.1602,16.8027,4.1602,11.9102-1.3867,16.8037-4.1602,8.7529-6.6328,11.583-11.583Z"
			/>
			<path
				className="c"
				d="M904.0273,553.46c0,2.0674,.4902,3.7803,1.4688,5.1396,.9785,1.3604,2.2285,2.2578,3.752,2.6914v1.1416c-5.1133,1.415-9.9512,2.4473-14.5195,3.0996-4.5674,.6533-9.3535,.9795-14.3555,.9795-12.3984,0-22.1865-3.3447-29.3652-10.0332-7.1777-6.6885-10.7666-15.7959-10.7666-27.3252,0-7.8311,1.876-14.6826,5.6279-20.5557s9.0264-10.4404,15.8242-13.7031c6.7969-3.2627,14.709-4.8945,23.7363-4.8945,4.6758,0,8.8105,.4893,12.3984,1.4678v-32.79c0-2.8271-.4883-5.001-1.4678-6.5254-.9785-1.5215-2.3936-2.6104-4.2412-3.2627v-1.1426h11.9082v105.7129Zm-38.8262-53.6719c-5.873,2.7197-10.4404,6.582-13.7031,11.583-3.2637,5.0039-4.8945,10.9297-4.8945,17.7822,0,9.7881,3.0176,17.5371,9.0547,23.2471,6.0352,5.709,14.2188,8.5645,24.5518,8.5645,6.0898,0,11.9619-.6523,17.6182-1.958v-61.666c-3.6973-1.0859-7.7764-1.6309-12.2344-1.6309-7.7236,0-14.5195,1.3613-20.3926,4.0781Z"
			/>
			<path
				className="c"
				d="M921.6465,499.4619c-1.0889-2.1738-2.1211-3.834-3.0996-4.9756s-1.9043-1.9297-2.7734-2.3652v-.8164h15.6611v.8164c-3.4814,1.5244-4.2979,4.2969-2.4473,8.3193l24.6338,54.4883,20.0664-43.8838-5.2207-12.0723c-.8721-2.0645-1.7949-3.5889-2.7734-4.5684-.9785-.9785-2.0674-1.7383-3.2627-2.2832v-.8164h15.498v.8164c-1.6318,.7617-2.667,1.7939-3.0996,3.0996-.4365,1.3047-.1631,3.0459,.8154,5.2197l23.6553,54.6514,24.6338-54.8145c.8691-2.1738,1.0039-3.8867,.4072-5.1387-.5986-1.249-1.7119-2.2559-3.3438-3.0176v-.8164h15.498v.8164c-.8721,.4355-1.8506,1.2236-2.9365,2.3652-1.0889,1.1416-2.0674,2.8018-2.9365,4.9756l-31.3223,68.1914h-.6533l-21.8594-50.2461-23.3291,50.2461h-.6523l-31.1592-68.1914Z"
			/>
			<path
				className="c"
				d="M1045.5488,548.4033c-3.3184-5.873-4.9756-12.5615-4.9756-20.0664,0-7.6113,1.6572-14.3555,4.9756-20.2285,3.3164-5.873,7.9121-10.4668,13.7852-13.7852,5.873-3.3164,12.5615-4.9756,20.0664-4.9756,7.6113,0,14.3281,1.6592,20.1475,4.9756,5.8164,3.3184,10.3848,7.9121,13.7031,13.7852,3.3164,5.873,4.9756,12.6172,4.9756,20.2285,0,7.5049-1.6592,14.1934-4.9756,20.0664-3.3184,5.873-7.8867,10.4971-13.7031,13.8662-5.8193,3.3701-12.5361,5.0576-20.1475,5.0576-7.5049,0-14.1934-1.6875-20.0664-5.0576-5.873-3.3691-10.4688-7.9932-13.7852-13.8662Zm62.2373-2.6924c2.8271-4.9473,4.2412-10.7383,4.2412-17.374,0-6.7422-1.4141-12.5869-4.2412-17.5371-2.8301-4.9473-6.6885-8.8096-11.583-11.583-4.8945-2.7725-10.4971-4.1592-16.8027-4.1592-6.3096,0-11.9092,1.3867-16.8037,4.1592-4.8936,2.7734-8.7275,6.6357-11.501,11.583-2.7734,4.9502-4.1602,10.7949-4.1602,17.5371,0,6.6357,1.3867,12.4268,4.1602,17.374,2.7734,4.9502,6.6074,8.8096,11.501,11.583,4.8945,2.7734,10.4941,4.1602,16.8037,4.1602,6.3057,0,11.9082-1.3867,16.8027-4.1602s8.7529-6.6328,11.583-11.583Z"
			/>
			<path
				className="c"
				d="M1136.9873,502.3984c0-2.8271-.4893-5.0293-1.4688-6.6074-.9785-1.5752-2.3936-2.6914-4.2412-3.3438v-1.1426h11.7461v11.583c2.0645-3.8057,5.0566-6.8232,8.9727-9.0537,3.915-2.2285,7.9375-3.3447,12.0723-3.3447,1.8477,0,3.4795,.1631,4.8936,.4893v9.7881h-.8154c-1.958-2.5-4.4043-3.752-7.3408-3.752-3.373,0-6.7451,1.3057-10.1152,3.916-3.3721,2.6094-5.873,5.8193-7.5039,9.625v44.6992c0,2.9365,.3516,4.9502,1.0605,6.0361,.7061,1.0889,1.876,2.0674,3.5068,2.9365v.9785h-15.1719v-.9785c1.6318-.8691,2.7734-1.8477,3.4268-2.9365,.6523-1.0859,.9785-3.0996,.9785-6.0361v-52.8564Z"
			/>
			<path
				className="c"
				d="M1183.4795,458.6777c0-2.8271-.4893-5.001-1.4688-6.5254-.9785-1.5215-2.3936-2.6104-4.2412-3.2627v-1.1426h11.9092v81.4062l35.7266-31.6494c2.1748-2.1738,2.1211-3.915-.1631-5.2197v-.9795h16.1514v.9795c-.9795,.4355-2.04,1.0342-3.1816,1.7939-1.1416,.7627-2.4219,1.7412-3.834,2.9365l-19.5762,17.293,22.6758,43.5576c1.958,3.4814,3.916,5.6025,5.873,6.3623v.9785h-15.1719v-.9785c3.3701-1.1953,4.1855-3.3164,2.4473-6.3623l-20.3916-39.4795-20.5557,18.1084v18.7607c0,2.9365,.3262,4.9502,.9785,6.0361,.6523,1.0889,1.8486,2.0674,3.5889,2.9365v.9785h-15.1719v-.9785c1.6318-.8691,2.7734-1.8477,3.4268-2.9365,.6523-1.0859,.9785-3.0996,.9785-6.0361v-96.5771Z"
			/>
			<path
				className="c"
				d="M1264.0684,555.2549c0,2.9365,.3262,4.9502,.9785,6.0361,.6523,1.0889,1.8486,2.0674,3.5889,2.9365v.9785h-15.1719v-.9785c1.6318-.8691,2.7734-1.8477,3.4268-2.9365,.6523-1.0859,.9785-3.0996,.9785-6.0361v-52.8564c0-2.8271-.4893-5.0293-1.4688-6.6074-.9785-1.5752-2.3936-2.6914-4.2412-3.3438v-1.1426h11.9092v63.9502Zm-8.0752-88.9912c-1.1426-1.1416-1.7129-2.582-1.7129-4.3232,0-1.8477,.5703-3.3164,1.7129-4.4043,1.1416-1.0859,2.582-1.6318,4.3232-1.6318,1.8477,0,3.3164,.5459,4.4043,1.6318,1.0859,1.0879,1.6318,2.5566,1.6318,4.4043,0,1.7412-.5459,3.1816-1.6318,4.3232-1.0879,1.1416-2.5566,1.7129-4.4043,1.7129-1.7412,0-3.1816-.5713-4.3232-1.7129Z"
			/>
			<path
				className="c"
				d="M1288.2109,502.3984c0-2.8271-.4893-5.0293-1.4688-6.6074-.9785-1.5752-2.3936-2.6914-4.2412-3.3438v-1.1426h11.583v9.626c2.5-3.3701,5.8447-6.1436,10.0332-8.3203,4.1846-2.1748,8.5645-3.2627,13.1318-3.2627,7.3945,0,13.1865,2.2305,17.374,6.6885,4.1855,4.4609,6.2812,10.6602,6.2812,18.5977v40.6211c0,2.9365,.3262,4.9502,.9785,6.0361,.6523,1.0889,1.7949,2.0674,3.4258,2.9365v.9785h-15.1719v-.9785c1.6318-.8691,2.7988-1.8477,3.5078-2.9365,.7061-1.0859,1.0605-3.0996,1.0605-6.0361v-39.9688c0-6.1992-1.6318-11.1191-4.8945-14.7637-3.2627-3.6426-7.7764-5.4648-13.54-5.4648-4.3516,0-8.458,1.0596-12.3174,3.1807-3.8613,2.1211-7.043,5.0859-9.543,8.8916v48.125c0,2.9365,.3262,4.9502,.9785,6.0361,.6523,1.0889,1.8486,2.0674,3.5889,2.9365v.9785h-15.1719v-.9785c1.6318-.8691,2.7734-1.8477,3.4268-2.9365,.6523-1.0859,.9785-3.0996,.9785-6.0361v-52.8564Z"
			/>
			<path
				className="c"
				d="M1423.125,568.7949c0,10.5479-2.9648,18.542-8.8916,23.9814-5.9287,5.4365-14.5469,8.1572-25.8564,8.1572-2.7207,0-5.5215-.1914-8.4023-.5713-2.8828-.3828-5.1387-.79-6.7695-1.2236v-9.9512h.8154c.543,.6523,1.0859,1.2236,1.6309,1.7129,.543,.4893,1.3594,1.0605,2.4473,1.7129,1.1953,.7598,2.7988,1.3867,4.8125,1.876,2.0117,.4893,3.9971,.7344,5.9551,.7344,18.7041,0,28.0596-8.6465,28.0596-25.9385v-6.3623c-6.1992,2.3906-12.6182,3.5889-19.25,3.5889-7.7236,0-14.4658-1.5498-20.2295-4.6494-5.7656-3.0996-10.2246-7.4482-13.377-13.0518-3.1562-5.5996-4.7314-12.207-4.7314-19.8203,0-7.8311,1.9014-14.6826,5.71-20.5557,3.8057-5.873,9.1074-10.4131,15.9062-13.6221,6.7949-3.207,14.7637-4.8125,23.8994-4.8125,3.5889,0,7.1777,.1914,10.7666,.5713,3.5889,.3818,7.8311,1.0605,12.7246,2.0391v1.1416c-1.6309,.3262-2.9102,1.1982-3.833,2.6104-.9258,1.415-1.3867,3.1553-1.3867,5.2207v67.2119Zm-38.9082-68.9248c-5.8193,2.7734-10.3594,6.6631-13.6221,11.6641-3.2627,5.0039-4.8945,10.8232-4.8945,17.4561,0,9.6807,2.9082,17.4297,8.7285,23.2471,5.8164,5.8193,13.6211,8.7275,23.4102,8.7275,6.958,0,13.3203-1.3584,19.0869-4.0781v-60.0352c-2.6104-.4336-4.7314-.7344-6.3623-.8975-1.6318-.1631-3.5898-.2441-5.873-.2441-7.8311,0-14.6572,1.3867-20.4736,4.1602Z"
			/>
		</g>
	</svg>
);
